/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 33번째 로그에서는 Matz 블로그 부활, 기술서전, 구글 클라우드 장애, WWDC 2019 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gnu.org/software/repo-criteria.html"
  }, "GNU Ethical Repository Criteria - GNU Project - Free Software Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ridi/select-frontend"
  }, "ridi/select-frontend: RIDI Select Web Frontend")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "stdout.fm are creating 클라우드, 소프트웨어 개발, 전자 제품에 대해 이야기하는 프로그래머들의 팟캐스트 | Patreon")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://odkmedia.bamboohr.com/jobs/"
  }, "ODK media BambooHR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://map.naver.com/local/siteview.nhn?code=18790650"
  }, "미림타워: 네이버 지도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://acornpub.co.kr/book/language-structure"
  }, "마츠모토 유키히로의 프로그래밍 언어 만들기 - Ruby 및 Streem을 통한 언어 제작 과정 살펴보기 | 에이콘출판사"), " -"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.imaso.co.kr/"
  }, "마이크로소프트웨어 – MICROSOFTWARE")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gihyo.jp/magazine/wdpress"
  }, "(일본어) WEB+DB PRESS｜gihyo.jp … 정보평론사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://trendy.nikkeibp.co.jp/linux/"
  }, "(일본어) 닛케이 리눅스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/matz/streem"
  }, "matz/streem: prototype of stream based programming language")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://langdev.org/"
  }, "LangDev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://magazine.rubyist.net/"
  }, "(일본어) 루비 매거진(るびま)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://magazine.rubyist.net/articles/0008/0008-Legwork.html"
  }, "(일본어) Rubyist를 위한 다른 언어 탐방 のための他言語探訪 【제 1 회】 Python | 루비 매거진")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://matzdiary.herokuapp.com/20190531.html"
  }, "(일보넝) Matz일기(2019-05-31)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ruby-lang.org/ja/news/2003/08/04/20030804/"
  }, "(일본어) Ruby 1.8.0 リリース!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lambdanote.com/products/ruby-ruby"
  }, "(일본어) 루비로 만드는 루비 - 제로부터 다시 배우는 프로그래밍 언어 입문 - 기술출판사 람다노트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ioccc.org/"
  }, "The International Obfuscated C Code Contest")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubykaigi.org/2018/presentations/tric.html#jun02"
  }, "(일본어) TRICK 2018 (FINAL) - RubyKaigi 2018")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ascii.jp/elem/000/001/230/1230449/"
  }, "(일본어) ASCII.jp：루비로 배우는 루비")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko"
  }, "프로그래밍 클라우드 기술 블로그 — 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techbookfest.org/"
  }, "(일본어) 기술서전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://booth.pm/ja/browse/%E6%8A%80%E8%A1%93%E6%9B%B8?q=%E6%8A%80%E8%A1%93%E6%9B%B8%E5%85%B86"
  }, "(일본어) 기술서전 6의 판매 서적 | BOOTH")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/container-orchestration-101-with-docker-and-aws-elastic-container-service"
  }, "아마존 엘라스틱 컨테이너 서비스(ECS)와 도커(Docker)로 시작하는 컨테이너 오케스트레이션 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://status.cloud.google.com/incident/compute/19003"
  }, "Google Cloud Status Dashboard")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://landing.google.com/sre/sre-book/chapters/embracing-risk/"
  }, "Chapter 3 - Embracing Risk - Site Reliability Engineering")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.newsis.com/view/?id=NISX20190531_0000667915&cID=13001&pID=13000"
  }, "통신장애 발생시 이용자에 즉시 통보 의무화 :: 공감언론 뉴시스통신사 ::")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://stdout.fm/6/"
  }, "stdout_006.log: KT 서울 서북부 통신 장애, AWS re:Invent 2018 | 개발자 팟캐스트 stdout.fm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ui.com/software/"
  }, "Ubiquiti Networks - Software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/mac-pro/"
  }, "Mac Pro - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/channel/UCZTjbqrV3s1zIWfEqliBhYA"
  }, "방구석 리뷰룸 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://the-edit.co.kr/"
  }, "디에디트 – THE EDIT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://esta.cbp.dhs.gov/esta/application.html;jsessionid=-p0mEVqGUzzSJXpNUj2Lx61_Sz2luK_EgUzgDO_wr4aMq-jdbh2h!1060648144?execution=e1s1"
  }, "홈 | Official ESTA Application Website, U.S. Customs and Border Protection")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/thekitze/status/1135652364105519104"
  }, "Kitze on Twitter: \"Jony Ive explaining the new functionality to Tim Cook #WWDC19 https://t.co/hwY2JfzIeG\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/pro-display-xdr/"
  }, "Pro Display XDR - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/ipados/ipados-preview/"
  }, "iPadOS Preview - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/ios/ios-13-preview/"
  }, "iOS 13 Preview - Apple")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.apple.com/xcode/swiftui/"
  }, "Xcode - SwiftUI - Apple Developer")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
